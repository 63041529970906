<template>
  <div class="advance">
    <div class="title">高等数学综合训练</div>
    <div class="desc">
      高数通用部分的经典题，精编提示和总结，适合基础和强化阶段
    </div>
    <div class="tools">
      <div class="tools-btn" @click="handleTools('free')">免费试用</div>
      <div class="tools-line" />
      <div class="tools-btn" @click="handleTools('buy')">购买全册</div>
    </div>

    <div class="directory">
      <div
        v-for="(item, index) in directory"
        class="directory-item"
        :key="index"
      >
        <div class="directory-item__index">{{ index + 1 }}</div>
        <div>
          <div
            :class="
              `directory-item__title ${item.is_unlock ? 'unlock' : 'lock'}`
            "
          >
            {{ item.name }}
          </div>
          <div class="directory-item__desc">
            <template v-if="item.is_unlock"
              >共{{ item.childs.length }}个专题</template
            >
            <template v-else>点击购买，成功后可用</template>
          </div>
        </div>
        <div>
          <div
            class="directory-item__tools"
            v-if="item.is_unlock"
            @click="handleContinue(item, index)"
          >
            <p v-if="item.learn_rate > 0">继续</p>
            <van-icon color="#9498AA" name="arrow" />
          </div>
          <i
            class="iconfont icongeneral-suoding"
            @click="handleUnLock(item)"
            v-else
          />
        </div>
      </div>
    </div>
    <propPay ref="propPay" />
  </div>
</template>

<script type="application/ecmascript">
import propPay from '@/components/pay.vue'
export default {
  data () {
    return {
      number: ["一","二","三","四","五","六","七","八","九"],
      directory: [], // 高数目录
      isRegister: true // 是否注册
    }
  },
  components: {
      propPay,
  },
  methods: {
    handleTools (type) {
      console.log(type)
      if(type=='buy'){
        wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active=1'});
      }else{
        location.href = '/guide?name=gaoshushang'
      }
      
    },

    // 点击继续
    handleContinue (row, index) {
      let query = {
        name: row.name,
        number: this.number[index], // 章节数
        book: 'gaoshushang'
      }
      this.$router.push({ path: '/topic', query })
    },

    // 点击解锁
    async handleUnLock (item) {
      // this.$refs.propPay.showBottom = true;
      wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active=1'});
      return
      if (this.isRegister) {
          let parma = {
            openid: localStorage.openid,
            uasrname: 'haha'
          }

          try {
            const ret = await this.$api.register({
              parma,
              method: 'post'
            });
            if (!ret.wq_access_token) {
              this.$toast(ret.inner_mess + ret.message);
              return
            }
            localStorage.access_token = ret.wq_access_token;
            if (ret.is_new_unlock) {
              this.$dialog('本章解锁成功');
              this.queryDirectory();
              return
            }
            // 去购买
            location.href = item.buy
          } catch (err) {
            this.$toast(err.message);
          }
      } else {
        this.$router.push('/token');
      }
    },

    async queryDirectory () {
      try {
        const ret = await this.$api.gaoshushang({});
        const {list = [], is_registed} = ret;
        list.shift()
        this.isRegister = is_registed;
        this.directory = [...list];
        console.log(this.directory);
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted () {
    this.queryDirectory();
  }
}
</script>

<style lang="less" scoped>
.advance {
  padding: 24px 20px;

  .title {
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2a3155;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .desc {
    height: 18px;
    font-size: 11px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #9498aa;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .tools {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    &-btn {
      height: 20px;
      font-size: 14px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #415fd5;
      line-height: 20px;
    }

    &-line {
      height: 16px;
      width: 1px;
      margin: 2px 24px;
      background: #415fd5;
    }
  }

  .directory {
    padding: 0 16px;
    margin-top: 28px;
    font-family: SourceHanSansSC-Normal, SourceHanSansSC;
    font-weight: 400;

    .unlock {
      color: #2a3155;
    }

    .lock {
      color: #9498aa;
    }

    &-item {
      padding: 12px 20px;
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      position: relative;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 28px;

      &__index {
        position: absolute;
        top: -20px;
        left: -20px;
        width: 40px;
        height: 40px;
        background: #f9c200;
        border-radius: 50%;
        font-size: 20px;
        font-weight: 800;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }

      &__title {
        height: 24px;
        font-size: 16px;
        text-align: left;
        color: #2a3155;
        line-height: 24px;
        margin-bottom: 4px;
      }

      &__desc {
        text-align: left;
        height: 20px;
        font-size: 14px;
        color: #9498aa;
        line-height: 20px;
      }

      &__tools {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        > p {
          margin-right: 4px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #bfc1cc;
        }
      }
    }

    .directory-item:nth-child(even) {
      .directory-item__index {
        background: #b9bfd3;
      }
    }
  }
}
</style>


